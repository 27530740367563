// Submissions
const submissionsServiceName = 'submissionsService';
const testsServiceName = 'testsService';

// Users
const authorizationServiceName = 'authorizationService';

const usersSliceName = 'users';

const usersServiceName = 'usersService';

export {
    submissionsServiceName,
    testsServiceName,
    authorizationServiceName,
    usersSliceName,
    usersServiceName,
};
